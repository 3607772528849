.middle-section {
  flex: 1;
  float: $float-left;
  width: $width70;
}

.right-section {
  background-color: $light-gray;
  border-left: 1px solid $gray;
  float: $float-left;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: $padding1x;
  width: $width30;
}

.floating-button {
  bottom: 23px;
  position: $position-fixed;
  right: 23px;
  z-index: 999;

  button {
    background-color: $blue800 $important;
  }

  >div {
    float: $float-right;
  }
}

//==== Media query ====//
@media screen and (min-width:1601px) and (max-width:1920px) {

  .middle-section {
    width: 78%;

    .floating-button {
      right: 20.3%;
    }
  }

  .right-section {
    width: $width30 - 8%;
  }
}

@media screen and (min-width:1441px) and (max-width:1600px){

  .middle-section {
    width: 75%;

    .floating-button {
      right: 22.4%;
    }
  }

  .right-section {
    width: $width30 - 5%;
  }
}

@media screen and (min-width:1366px) and (max-width:1440px){

  .middle-section {

    .floating-button {
      right: 26%;
    }
  }
}


@media screen and (min-width:1200px) and (max-width:1365px){

  .middle-section {

    .floating-button {
      right: 25.5%;
    }
  }
}

@media screen and (min-width:1024px) and (max-width:1199px){

  .middle-section {
    width: $width60 + 5%;

    .floating-button {
      right: 28%;
    }
  }

  .right-section {
    width: 35%;
  }
}

@media screen and (min-width:1101px) and (max-width:1199px){

  .middle-section {
    width: $width60 + 5%;

    .floating-button {
      right: 29.5%;
    }
  }

  .right-section {
    width: 35%;
  }
}

@media screen and (min-width:1024px) and (max-width:1100px){

  .middle-section {
    width: $width60 + 5%;

    .floating-button {
      right: 28%;
    }
  }

  .right-section {
    width: 35%;
  }
}
