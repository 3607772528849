.circular-loader-container {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 50%;
  left: 50%;
  border-radius: 50% !important;
  z-index: 9999 $important;

  .circular-loader {
    height: 35px !important;
    width: 35px !important;
    margin-top: 8px;
    margin-left: 8px;
  }
}
