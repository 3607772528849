.intl-tel-input {
  width: 100% $important;


  .form-control,
  .flag-container {
    background: transparent $important;
    border: 0 $important;
    border-bottom: 1px solid $gray2 $important;
    border-radius: 0 $important;
    box-shadow: none $important;
    height: 34px;
    outline: none $important;
  }

  .form-control:focus {
    outline: none $important;
  }

  .selected-dial-code {
    font-size: 12px;
  }

  .selected-flag {
    outline: none $important;
  }

  &.country-dropdown {
    .form-control {
      padding-left: 12px $important;
    }

    .selected-flag {
      background-color: transparent $important;
      width: 100% $important;

      .iti-flag {
        display: none;
      }
    }
  }
}
