@import 'variables';

//===== Raised Button =====//
%darkcyan {
  background-color: $cyan700 $important;
}

.btn-darkcyan {
  @extend %darkcyan;
}

%darkorange {
  background-color: $orange700 $important;
}

.btn-darkorange {
  @extend %darkorange;
}

%darkyellow {
  background-color: $yellow800 $important;
}

.btn-darkyellow {
  @extend %darkyellow;
}

%blue {
  background-color: $blue800 $important;
}

.btn-blue {
  @extend %blue;
}

%brown {
  background-color: $brown600 $important;
}

.btn-brown {
  @extend %brown;
}

%red {
  background-color: $red500 $important;
}

.btn-red {
  @extend %red;
}

//===== Flat Button =====//

%flat-darkcyan {
  color: $cyan700 $important;
}

.btn-flat-darkcyan {
  @extend %flat-darkcyan;
}

%flat-darkorange {
  color: $orange700 $important;
}

.btn-flat-darkorange {
  @extend %flat-darkorange;
}

%flat-darkyellow {
  color: $yellow800 $important;
}

.btn-flat-darkyellow {
  @extend %flat-darkyellow;
}

%flat-blue {
  color: $blue800 $important;
}

.btn-flat-blue {
  @extend %flat-blue;
}

%flat-brown {
  color: $brown600 $important;
}

.btn-flat-brown {
  @extend %flat-brown;
}

%flat-red {
  color: $red500 $important;
}

.btn-flat-red {
  @extend %flat-red;
}

//===== Truncate =====//
%truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate {
  @extend %truncate;
}

//==== Disabled =====//
.disabled {
  pointer-events: none $important;
}

//==== Dropdown ====//
%dropdown-shadow {
  box-shadow: $shadow 0 1px 6px, $shadow 0 1px 4px $important;
}

//==== Text Adjust ====//
%text-adjust {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.state-btn {
  height: 30px $important;
  line-height: 30px $important;

  span {
    font-size: 12px $important;
    font-weight: 500;
  }
}

button .disabled,
button:disabled {
  pointer-events: none;
  background-color: #DFDFDF !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #9F9F9F !important;
  cursor: default;
}

.modal-footer button:disabled,
.modal-footer button .disabled {
    color: #9F9F9F !important;
}

button.MuiButtonBase-root.MuiButton-root {
  border-radius: 0;
  padding: 5px 18px;
  // letter-spacing: 0;
}

.MuiInput-root.Mui-focused::after {
  border-color: $cyan700 $important;;
}

.MuiInput-root.Mui-error.Mui-focused::after {
  border-color: #f44336 !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $cyan700 $important;;
}

.MuiFormLabel-root.Mui-error.Mui-focused {
  color: #f44336 !important;
}

.MuiDialog-paper {
  width: 768px !important;

  .MuiDialogTitle-root {
    .MuiTypography-root {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .MuiDialogContent-dividers {
    padding-top: 0px;
    padding-bottom: 40px;

    .primary-text {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .MuiDialogContent-dividers > .get-started-modal {
    padding: 16px 24px;
  }
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  color: rgba(0, 0, 0, 0.6);
  overflow-y: visible;
}

.MuiDialog-paperWidthSm {
  max-width: 800px !important;
}

.MuiPickersModal-dialogRoot {
  width: 310px !important;

  .MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected {
    background-color: #0097a7;
  }
  .MuiPickersDay-daySelected:hover {
    background-color: #0097a7;
  }
  .MuiButton-textPrimary, .MuiTypography-colorPrimary {
    color: #0097a7;
  }
  .MuiIconButton-root:hover {
    background-color: #0097a742;
}
}

.MuiPaper-rounded {
  border-radius: 2px !important;
}

.MuiFormControl-root {
  .MuiInput-underline::before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); 
  }
}