.chips {
  border-bottom: 2px solid $blue800;
  font-size: $font-size16;
  line-height: 1;
  min-height: $chip-min-height;
}

.chips-input {
  border: 0;
  display: $display-inline-block;
  font-size: .9rem;
  margin-bottom: $chip-x-spacing;
  min-height: $chip-min-height;
  outline: none;
  width: $width30 + 3%;
}

.chip {
  display: inline-block;
  margin: $chip-x-spacing 30px $chip-x-spacing 0;
  position: relative;

  .chip-value {
    background: $chip-background;
    border-radius: $chip-border-radius 0 0 $chip-border-radius;
    color: $black;
    display: inline-block;
    font-weight: bold;
    height: $height3x + 2px;
    line-height: $line-height2x + 2px;
    padding: $chip-x-spacing;
    padding-left: $chip-y-spacing;
    padding-right: $chip-y-spacing / 2;
  }

  .chip-delete-button {
    background: $chip-background;
    border: 0;
    border-radius: 0 $chip-border-radius $chip-border-radius 0;
    bottom: 0;
    color: $black;
    cursor: pointer;
    line-height: .5;
    outline: none;
    padding: $chip-x-spacing;
    position: $position-absolute;
    right: -$chip-button-width;
    top: 0;

    div {
      background: $chip-cross-bg;
      border-radius: 50%;
      color: $white;
      font-size: $font-size17;
      height: $height2x;
      line-height: $line-height1x + 9px;
      position: $position-relative;
      text-align: $text-center;
      width: $width2x;
    }
  }
}

.email-label {
  color: $blue800;
  font-size: $font-size14;
  margin: $margin1x $margin0x;
}
