.aggregation-units-page {
  padding: $padding1x;

  .row {
    margin: 0 -10px $important;
  }

  .page-header-section {
    margin-bottom: 15px;
  }

  .list {
    .edit-btn {
      min-width: 70px $important;
      height: 25px $important;
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
      color: rgb(0, 188, 212)
    }
  }
}

.aggregation-list {
  margin-bottom: $margin6x;
}

.middle-section {
  .aggregation-units-page {

    .btn-section {
      padding-left: 0 $important;
    }

    .add-button {
      float: $float-right;
      margin-top: $margin1x / 2;
      position: $position-relative;
      right: 0;
    }
  }
}
//==== Full width Container ====//

.full-width-container {
  .page-header {
    width: 80%;
  }
}

//==== Edit and craete aggregation ====/
.add-edit-aggregation {
  margin-bottom: 80px;

  .input-field {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  span {
    cursor: pointer;
  }

  h2 {
    padding: $padding1x $padding0x;
  }

  h4 {
    margin-bottom: 5px;
  }

  .add-aggre-btn {
    min-width: 0 $important;
  }

  .upload-image {
    border: 3px solid $blue800;
    color: $blue800;
    cursor: pointer;
    font-size: 40px;
    font-weight: 900;
    height: $height9x;
    line-height: $line-height0x;
    margin: $margin1x 0;
    overflow: hidden;
    width: 90px;

    i {
      cursor: pointer;
      display: -webkit-box;
      display: flex;
      font-style: normal;
      font-weight: 300;
      margin-top: 40px;
      padding-left: 31px;

    }

    input {
      cursor: pointer;
      display: $display-block $important;
      height: $height9x $important;
      opacity: 0 $important;
      overflow: hidden $important;
      width: 90px $important;
    }
}

}

//==== Edit aggregation ====//
.checkbox-section {
  .checkbox {
    label {
      @extend %truncate;
      width: 55% $important;
    }
  }
}

.edit-aggregation-button {
  .margin-btn {
    margin: 5px 3px;
  }
}

.change-image-section {
  text-align: center;
  white-space: nowrap;

  img {
    margin: $margin1x 0;
    max-height: 150px;
    max-width: $width100;
  }

  .change-img-btn {
    margin-bottom: 15px;
    background: $orange700 $important;
  }
}

//==== Aggregtion unit sucess message ====//
.aggregation-sucess-message {
  text-align: $text-center;

  h3 {
    font-size: $font-size17;
    line-height: $line-height2x + 2px;
    margin: $margin3x / 2 $margin0x $margin2x;
  }

  .aggr-name {
    color: $cyan700;
  }

  .org-name {
    color: $blue800;
  }
}


@media screen and (min-width:768px) and (max-width:1023px){
  .aggregation-units-page {
    padding: 10px 5px $important;

     .title-section {
      margin-left: 25px $important;
    }
  }
}

@media screen and (min-width:1024px) and (max-width:1120px){
  .add-button {
    span {
      font-size: 12px $important;
      padding: 0 6px $important;
    }
  }
}

@media screen and (min-width:768px) and (max-width:950px){
  .middle-section {
  .aggregation-units-page {
    
    .add-button {
      span {
        font-size: 12px $important;
        padding: 0 6px $important;
      }
    }
    .floating-button {
      right: 38% $important;
    }

    .title {
      margin-right: $margin0x $important;
      width: 70% $important;
    }
  }
}
}
