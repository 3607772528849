//==== Owner ====//
.owner-label {
  color: $dark-gray;
  font-size: 15px;
  margin-left: 5px;
}
//===== Edit Organization Section =====//
.edit-address-section {
  h2 {
    margin-bottom: $margin1x;
  }
  .MuiFormControl-root {
    margin-top: 14px;
  }
}
.phoneerror {
  color: $red500 $important;
  font-size: 13px $important;
  margin-top: -10px;
}

//==== Address ====//
.address-section {
  h2 {
    margin-bottom: $margin1x;
  }

  h3 {
    font-size: $font-size20 $important;
    margin: $margin1x $margin0x $margin1x - 5px;
  }

  p {
    font-size: font-size18 $important;
    line-height: $line-height2x + 4px;
    margin-left: $margin3x;

    .icon {
      font-size: $font-size16 $important;
      margin-left: -30px;
      margin-right: 10px;
   }

    .fa-phone {
      margin-right: $margin1x + 4px;
    }

    .icon-globe-line {
      font-weight: 900;
    }
 }

  .map {
    height: 200px;
    margin: $margin1x auto;
    overflow: hidden;
    position: $position-relative;
    text-align: -webkit-center;
    width: $width100;
  }

  .edit-btn-section {
    margin: $margin1x + 5px $margin0x;
    text-align: right;

    .btn-darkorange {
      min-width: 0 $important;
    }
  }
}

.input-label {
  color: $input-label-color;
  font-size: $font-size12;
  margin: 10px 0 5px;
}

.timezone-picker-list {
  background-color: $white $important;
}

.timezone-picker-textfield input {
  background: $transparent $important;
  border: 0 $important;
  border-bottom: 1px solid $gray2 $important;
  padding-left: 0 $important;
}

.timezone-picker-list-item-active,
.timezone-picker-list-item:hover {
  background: $blue800 $important;
  color: $white $important;
}

.timezone-picker-list-item {
  height: $height5x;
  line-height: $line-height4x;
}
//==== Transfer Ownership ====//
.transfer-section {
  margin-bottom: $margin7x;

  .input-field {
    margin-top: 10px;
  }
  
  .btn-darkcyan {
    margin-top: 10px;
  }

  &:first-child div {
    width: $width100 $important;
  }

  h2 {
    margin: $margin2x $margin0x $margin1x;
  }
}

//====media query=======//

@media screen and (min-width:768px) and (max-width:1023px){
  .transfer-section h2 {
    font-size: $font-size18;
  }
}
