%input-focus {
  box-shadow: 0 0 0 1000px $light-gray inset $important;
  color: $black $important;
  -webkit-text-fill-color: $black $important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  @extend %input-focus;
  background-color: $white $important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: $white $important;
}

.error {
  color: $red500;
}

.page-subheader {
  font-size: $font-size18;
  padding: $padding1x $padding0x $padding2x;
}

.underline {
  border-bottom: 1px dashed;
}


.tip-message {
  font-size: 14px;
  margin-top: 7px;

  .icon-enter {
    border: 1px solid $blue800;
    border-radius: 2px;
    color: $blue800;
    font-size: 12px;
    font-weight: 700;
    margin-left: 2px;
    padding: 2px;
  }
}

.input-field {
  width: $width100 $important;
}
