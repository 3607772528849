.organizations-list-page .data-table {
  >div>:last-child {
    // border: 1px solid $red500;
    height: 70vh $important;
  }
}

.tags-page .data-table {
  >div>:last-child {
    // border: 1px solid $red500;
    height: 54vh $important;
  }
}

.view-request-list .data-table {
  >div>:last-child {
    // border: 1px solid $red500;
    height: 70vh $important;
  }
}

//==== Media Query ====//
// @media screen and (min-height: 800px) and (max-height: 1080px) {
//   .organizations-list-page .data-table {
//     >div>:last-child {
//       border: 1px solid $red500;
//       height: 750px $important;
//     }
//   }
//
//   .tags-page .data-table {
//     >div>:last-child {
//       border: 1px solid $red500;
//       height: 630px $important;
//     }
//   }
// }
//
// @media screen and (min-height: 600px) and (max-height: 799px) {
//   .organizations-list-page .data-table {
//     >div>:last-child {
//       border: 1px solid $red500;
//       height: 450px $important;
//     }
//   }
//
//   .tags-page .data-table {
//     >div>:last-child {
//       border: 1px solid $red500;
//       height: 330px $important;
//     }
//   }
// }
