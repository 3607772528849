.tags-page {
  padding: $padding1x;

  table {
    .sortable-columns {
      cursor: pointer;
    }

    th i.sort-icon {
      font-size: 15px;
      padding-right: 5px;
    }

    th:last-child {
      text-align: $text-right $important;
      width: 70px;
    }

    td:last-child {
      padding: $padding0x $padding1x + 2px $important;
      width: 70px;
    }

    .table-data {
      .MuiIconButton-root {
        color: black
      }
    }
    p {
      @extend %truncate;
    }
  }

  .floating-button {
    bottom: 13px $important;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: rgb(0, 188, 212)
  }

  .MuiCheckbox-colorSecondary {
    color: rgb(0, 0, 0);
  }

  .MuiTableCell-root {
    padding: 0px;
    width: 296px;
    padding-left: 20px;
  }
}

//==== Pair QR code button inside table====//
.qr-pair-btn {
  background-color: $blue800 $important;
  height: $height3x $important;
  line-height: $line-height3x $important;

  div {
    height: $height3x $important;
  }
  
  span {
    font-size: $font-size14 $important;
  }
}

//==== Dropdown ====//
.MuiMenu-list {
  li:hover {
    background-color: $blue800 $important;
    color: $white $important;
  }
}

//=====media query=====//

@media screen and (min-width:768px) and (max-width:1023px){
  .tags-page {
    .title-section {
      margin-left: 20px $important;
    }

    .floating-button {
      right: 20px $important;
    }

    .page-subheader {
      padding-left: 10px $important;
    }
  }
}
