.csv-upload {
  margin-top: 20px;
  .upload-btn {
    margin-right: $margin1x;
    background: $brown600 $important;
  }

  .file-name {
    pointer-events: none;
    width: $width80 $important;
  }

  .tip-message {
    a {
      color: $blue800;
      font-weight: bold;
      margin-left: 5px;
    }
  }
}

.file-input {
  bottom: 0;
  cursor: pointer;
  font-size: $font-size20;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: $position-absolute;
  right: 0;
  top: 0;
  width: $width100;
}

@media screen and (min-width:768px) and (max-width:1023px){
  .csv-upload .file-name {
    width: 70% $important;
  }
}
