.MuiTableCell-root {
  font-size: 13px;
}

.prevent-cell-click {
  position: $position-relative;
}

.prevent-cell-click-wrapper::before {
  bottom: 0;
  content: '';
  left: 0;
  right: 0;
  top: 0;
}


@media screen and (min-width:767px) and (max-width:1023px){
  .prevent-cell-click {
    padding-right: $padding1x $important;
  }
}
