.auth0-lock.auth0-lock .auth0-lock-tabs-container,
.auth0-lock-social-buttons-container,
.auth0-lock-close-button {
  display: none;
}

// .auth0-lock.auth0-lock .auth0-lock-header-logo {
//   height: 38px $important;
// }

.auth0-lock-form p {
  display: none;
}

.auth0-lock-last-login-pane p {
  display: block $important;
}

.auth0-lock-badge-bottom,
.auth0-lock-alternative {
  display: none;
}

// .auth0-lock.auth0-lock .auth0-lock-header-welcome {
//   margin-top: 10px;
// }

.auth0-lock-name {
  margin-top: -8px;
}
