//==== Report page header ====//
.report-header-section {
  background-color: $white;
  min-height: $height4x + 5px;
  padding: 10px 15px $important;

  .title {
    max-width: 30%;

    .title-org-name {
      margin: 0 5px $important;
    }

    .fa {
      font-size: 18px $important;
    }
  }

  h2 {
    font-size: $font-size20;
  }

  .text-button {
    color: $blue800;
    cursor: pointer;
    display: table;
    font-size: $font-size20;
    line-height: 25px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

//==== Report Page ====//

.report-page {
  background-color: $report-page-background;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: $padding1x;

  >div:last-child {
    margin-bottom: 70px;
  }

  .report-name-section {
    float: $float-left;
    padding-right: $padding1x;
    width: $width50;
  }

  .name-time {

    h3 {
      @extend %truncate;
      margin-bottom: 5px;
    }

    h5 {
      @extend %truncate;
      color: $blue800;
    }

  }

  .report-sub-section {
    float: $float-left;
    width: $width50;

    .subscriber-btn {
      float: $float-right;
    }
  }

  .report {
    background-color: $white;
    display: flex;
    margin: 4px 0;
    min-height: 3 * $height10x;
    padding: $padding1x;
    position: inherit;
    width: $width100;
  }

  .report-image {
    margin: $margin2x $margin0x;
    text-align: $text-center;

    img {
      width: 150px;
    }
  }
}

.subscribers-section {
  margin: 80px 0 20px;
}

.member-sub-btn {
  height: 40px;
  padding-left: 0 $important;

  .flat-btn {
    color: $blue800 $important;
    float: $float-left;
  }

  .raised-btn {
    float: $float-right;
    min-width: 0 $important;

    .fa-plus {
      margin-right: 5px;
    }

    span {
      text-transform: uppercase;
    }
  }
}

.archive-btn {
  margin: 20px 0 10px;
  text-align: $text-right;
}

.report-subscribers-modal, .add-subscribers-modal, .archived-reports-modal {
  
  
  .btn-red {
    height: 25px;
  }

  .btn-darkyellow {
    height: 25px;
  }
}

//Media query

@media only screen and (min-width: 320px) and (max-width: 767px){
  .report-page {
    .report-name-section {
      width: 100%;
    }

    .title-section {
      padding-left: $padding0x $important;

      .text-button {
          display: inline-table;
      }

      h2:nth-child(2),
      span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .title {
        .fa {
          font-size: $font-size16 $important;
          top: -10px;
          margin-right: 2px;
        }
      }
    }

    .report {
      display: inline-block $important;

      .report-sub-section {
        width: 100%;

        .subscriber-btn {
          span {
          font-size: 12px $important; 
          padding: 0 6px $important;
          }
        }

        .col-lg-12 {
          padding-right: $padding0x $important;
        }

        .subscribers-section {
          margin-top: $margin0x $important;
        }
      }
    }

    .report-header-section {
      font-size: $font-size14;
      padding: 10px 0 10px 5px $important;
    }
  }
}


@media only screen and (min-width: 320px) and (max-width: 420px){
  .title-section {
    font-size: $font-size14 $important;
    span {
      margin-left: $margin0x $important;
    }
    .icon-button {
      padding: 0 $important;
    }
  }

  .member-sub-btn {
    padding-right: $padding0x $important;

    .flat-btn {
      span {
        font-size: $font-size12 $important;
        padding: $padding0x $important;
      }
    }
  }
  .report-header-section {
    .text-button {
      margin-left: 0 $important;
    }
  }
}


