%badge-selector {
  border-top: 1px dashed $gray;
  content: '';
  position: $position-absolute;
  top: 50%;
  width: 9999px;
}

.badge {
  display: $display-block;
  margin-top: $margin2x;
  overflow: hidden;
  text-align: $text-center;
  white-space: nowrap;

  >span {
    display: $display-inline-block;
    font-size: $font-size15;
    position: $position-relative;

    &::before {
      @extend %badge-selector;
      margin-right: $margin3x / 2;
      right: 100%;
    }

    &::after {
      @extend %badge-selector;
      left: 100%;
      margin-left: $margin3x / 2;
    }
  }
}
