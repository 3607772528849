.jump-to-dropdown {
  width: $width7x $important;

  svg {
    fill: $black $important;
  }

  hr {
    border-bottom: 2px solid $blue800 $important;
    transform: scaleX(1) $important;
  }
}

.jump-to-section {
  float: right;

  .jump-text {
    color: $blue800;
    font-weight: 500;
    margin-right: $margin2x;
    height:48px;
    line-height: 48px;
  }

  .MuiInput-underline::before {
    border-bottom: 2px solid #1565c0;

  }
}

.dropdown-list {
  margin-top: $margin2x;
}
