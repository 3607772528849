.action-bar {
  background-color: $action-bar-bg;
  border-radius: 0;
  box-sizing: border-box;
  display: flex;
  font-family: Roboto, sans-serif;
  height: 64px;
  line-height: 64px;
  padding: 0 24px;
  -webkit-tap-highlight-color: $transparent;
  transition: all 450ms cubic-bezier(.23, 1, .32, 1) 0ms;

  .action-button {
    text-align: $text-right;
    width: 100%;
  }

  h3 {
    @extend %truncate;
    color: $cyan700;
    padding: 0 10px;
    width: 200px;
  }
}

.remove-button-icon {
  >span:first-child::before {
    content: '\e903';
    font-family: icon $important;
    margin-right: 5px;
  }
}

.delete-button-icon {
  > span:first-child::before {
    content: '\e901';
    font-family: icon $important;
    margin-right: 5px;
  }
}

.action-app-bar {
  box-shadow: $shadow 0 1px 6px, $shadow 0 1px 4px;
  height: 64px;
  position: $position-relative $important;
  top: 0;
  width: $width100;
  z-index: 1301;

  .action-bar {
    position: $position-fixed $important;
    width: $width100;
  }

  .back-button {
    cursor: pointer;
    padding: 0 15px;
    width: 314px;

    .icon {
      margin-right: 7px;
    }
  }

  h3 {
    color: $cyan700;
    padding: 0 10px;
    width: 200px;
  }

  .action-button {
    text-align: $text-right;
    width: 100%;

    .delete-button-icon {
      color: $red500 $important;
    }
  }
}

.table-action-bar {
  .action-bar {
    height: $height5x $important;
    line-height: $line-height5x $important;
  }

  h3 {
    font-size: $font-size18;
  }

  .bar-flat-btn {
    color: $black $important;
  }
}

@media screen and (min-width:768px) and (max-width:1023px){
  .action-app-bar {
    .back-button {
    width: 130px;
    }
  }
}
