%scroll {
  &::-webkit-scrollbar {
    background: $gray2;
    border-radius: 10px;
    cursor: pointer;
    width: 12px;
   }

  &::-webkit-scrollbar-thumb {
    background: $dark-gray;
    border-radius: 10px;
    box-shadow: inset 0 0 6px $scroll-shadow;
    cursor: pointer;
  }

  &:hover::-webkit-scrollbar {
    background: $gray2;
   }
}


//==== Scroll ====/

.page-scroll {
  @extend %scroll;
  height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.right-section {
  @extend %scroll;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  // height: auto;
  // padding: .5rem;
  // -webkit-overflow-scrolling: touch;
  // -ms-overflow-style: none;
}

//==== Table scroll ====//
.modal-scroll,
.data-table {
  >div>:last-child {
    @extend %scroll;
  }
}
