@import 'base';
// @import '~normalize.css/normalize';

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
  font-family: roboto,sans-serif;
  color: $default-text-color;
  text-size-adjust:100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {overflow: hidden;}
*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
li,
a,
button,
input,
select,
textarea,
table tr td {
  // font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
  speak: none;
  text-rendering: optimizeLegibility;
  text-transform: none;
}

ul li {list-style: none;}
a {
  color: $black;
  text-decoration: none;
}

button {
  white-space: nowrap;
}
