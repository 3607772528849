.page-header-section {
  padding-right: 0 $important;

  .header-section {
   padding-left: $padding0x $important;
   padding-right: $padding0x $important;
  }

  .title-section {
    display: flex;
    line-height: 40px;
    margin: 0 $important;
    padding: $padding0x;

    .title {
      @extend %truncate;
      font-size: $font-size20;
      margin-right: 10px
    }

    .title-org-name {
      color: $blue800;
      margin-left: 5px;
    }
  }

  .icon-button,
  .text-button {
    span {
      color: $orange700 $important;
    }
  }

  .btn-section {
    >button {
      margin-top: 5px;
      position: $position-absolute;
      right: 15px;
    }
  }
}

@media screen and (min-width:768px) and (max-width:1023px){
  .page-header-section {
    padding-left: 0;
  }
}
