.tag-assign-modal {
  .badge {
    padding-top: 15px;
    width: $width100;
  }
}

.tag-qr-input-btn {
  .input-filed-btn {
    width: 77% $important;
    margin-top: 20px;
  }

  .input-field {
    margin-top: 20px;
  }

  .btn-blue,
  .btn-darkorange {
    float: right;
    margin-top: 30px;
    width: 143px;
  }
}

.MuiFormControl-root.MuiTextField-root.end-date-input {
  margin-left: 4%;
  margin-top: 25px;
  float: $float-left;
  width: $width50 - 2%;

  >div {
    width: $width100 $important;
  }
}

.MuiFormControl-root.MuiTextField-root.start-date-input {
  margin-top: 25px;
  float: $float-left;
  width: $width50 - 2%;

  >div {
    width: $width100 $important;
  }
}

@media screen and (min-width:768px) and (max-width:1023px){
  .tag-qr-input-btn .input-filed-btn {
    width: 70% $important;
}
}

