.side-nav {
  .icon {
    font-size: $font-size16 $important;
    margin-right: $margin1x + 6px;
  }

  span {
    color: $white $important;
    width: 2rem;

    &.disabled a,
    &.disabled span {
      color: $disabled-color $important;
     }
  }
  p {
    color: $white $important;
  }

  .active {
    background-color: $black $important;
    border-left: 4px solid $white $important;
  }

  .MuiListItem-button {
    padding-top: 8px $important;
    padding-bottom: 1px $important;
  }

  .MuiListItemText-root {
    margin-top: -32.5pt;
    margin-left: 2.2rem;
  }

  .active-info {
    .active {
      background-color: $active-info-bg $important;
      border-left: 4px solid $white $important;
    }
  }
  .active-info {
    .fa {
      margin-bottom: 7px $important;
    }
  }

  .org-submenu {
    .fa {
      margin-bottom: 5px;
    }
  }

  .MuiDrawer-paper {
    z-index: 0;
  }
}

.info-menu {
  line-height: $line-height2x $important;
  padding: 8px 0;
  white-space: normal $important;

  .icon-section {
    float: $float-left;
    height: $height4x;
    line-height: $line-height4x;
    width: 31px;

    span {
      font-size: $font-size17 $important;
    }
  }

  span {
    font-size: $font-size15 $important;
    }
}

.org-name-menu {

  .org-name {
    @extend %truncate;
    color: #fff;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    margin-left: $margin3x + 4px;
    margin-top: -($margin1x + 6px);
    padding-right: $padding1x;
    width: $width90 + 2%;
  }

  .icon-exit {
    float: right;
    font-size: 1em $important;
    line-height: 48px;
  }
}

//===== Menu Item =====//
.menu-item {
  >div>div {
    padding: 0 $important;
  }

  a {
    background: none;
    border: 10px;
    box-sizing: border-box;
    color: $white $important;
    cursor: pointer;
    display: block;
    font-family: roboto, sans-serif;
    font-size: 16px;
    font-weight: inherit;
    line-height: 48px;
    margin: 0;
    min-height: 48px;
    outline: none;
    padding: 0 16px;
    position: relative;
    -webkit-tap-highlight-color: $transparent;
    text-decoration: none;
    transform: translate(0, 0);
    transition: all 450ms cubic-bezier(.23, 1, .32, 1) 0ms;
    white-space: nowrap;
  }

  a:hover {
    background-color: #272f3a;
  }
}

.org-submenu {
  a {
    padding: 0 32px;
  }
}
//==== User Details ====//

.user-view {
  line-height: 20px;
  margin-left: 18px;
  pointer-events: none;

  .circle {
    border: 3px solid $white;
    border-radius: 50%;
    font-size: 1.5em;
    height: 50px;
    line-height: 44px;
    margin-top: 10px;
    text-align: center;
    width: 50px;
  }

  h3 {
    line-height: 24px;
    margin-top: 15px;
  }

  p {
    line-height: 15px;
    margin: 5px 0 15px;
  }
}

//==== Fixed bottom ====//
.sidebar {
  @extend %scroll;
  background-color: $sidebar-bg;
  color: $white $important;
  height: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
  position: initial;

  >div:last-child {
    margin-bottom: 50px;
  }
}

.logout-menu {
  background-color: $logout-bg $important;
  bottom: 0;
  position: $position-fixed;
  width: 255px $important;
  height: 50px;
  .MuiListItemText-root {
    margin-top: 2px;
    margin-left: 2px;
  }
  .MuiListItem-gutters {
    margin-top: 5px;
  }
}

//=====Media Query ========//

@media only screen and (max-width: 1023px) {
  .main-container {
    margin-left: 0 $important;
  }
}
