.organization-info-page {
  padding: $padding1x;

  .row {
    margin-left: -10px $important;
    margin-right: -10px $important;
  }

  .btn-makeowner {
    @extend %darkcyan;
    min-width: 124px $important;
    height: 25px $important;
  }

  .title {
    margin-left:  15px;
  }
  .btn-section {
    padding: 0 $important;

    >button {
      float: $float-right;
      margin-top: $margin1x / 2;
      position: $position-relative;
      right: 0;
    }
  }

  .member-scroll {
    padding-bottom: 2 * $padding10x $important;
    margin-right: -$margin1x;
  }

  .member-page-header {
    height: 110px;
    position: $position-relative;
  }

  .fixed-page-header {
    background: $white;
    margin: -$margin1x;
    padding: $padding1x;
    padding-left: 0 $important;
    padding-right: 0 $important;
    position: $position-fixed;
    width: 62%;
    z-index: 1;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: rgb(0, 188, 212)
  }
}

.pending-jump-section {
  padding: 0 $important;

  // .btn-flat-darkorange {
  //   padding: 0 $important;
  // }
}

.separator {
  span {
    font-size: 20px;
  }
}

//==== Media query ====//
@media screen and (min-width:1601px) and (max-width:1920px) {

  .organization-info-page {
    .fixed-page-header {
      width: 67%;
    }
  }
}

@media screen and (min-width:1441px) and (max-width:1600px){

  .organization-info-page {
    .fixed-page-header {
      width: 62%;
    }
  }
}

@media screen and (min-width:1366px) and (max-width:1440px){

  .organization-info-page {
    .fixed-page-header {
      width: 56%;
    }
  }
}

@media screen and (min-width:1200px) and (max-width:1365px){

  .organization-info-page {
    .fixed-page-header {
      width: 54%;
    }
  }
}

@media screen and (min-width:1101px) and (max-width:1199px){

  .organization-info-page {
    .fixed-page-header {
      width: 49%;
    }
  }
}

@media screen and (min-width:1024px) and (max-width:1100px){

  .organization-info-page {
    .fixed-page-header {
      width: 47.5%;
    }
  }
}

@media screen and (min-width:768px) and (max-width:1023px){
  .main-container {
    .middle-section {
      width: 65%;
    }

    .right-section {
      width: 35%;
    }
  }

  .organization-info-page {
    .pending-jump-section {
      .btn-flat-darkorange {
        margin-left: -12px $important;
      }
    }
    .floating-button {
      right: 37.5% $important;
    }
    .fixed-page-header {
      width: 63%;
    }
  }
}





