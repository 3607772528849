//==== colors ==== //
$cyan700: #0097a7;
$orange700: #f57c00;
$yellow800: #f9a825;
$red500: #ff5252;
$blue800: #1565c0;
$brown600: #6d4c41;
$light-gray: #fafafa;
$gray2: #ddd;
$white: #fff;
$gray: 	#808080;
$dark-gray: #bdc3c7;
$black: #000;
$disabled-color: #9e9e9e;
$sidebar-bg:  #2f3b4c;
$active-info-bg: #f4511e;
$logout-bg: #2a3344;
$scroll-shadow: rgba(0, 0, 0, .5);
$shadow: rgba(0, 0, 0, .117647);
$transparent: rgba(0, 0, 0, 0);
$action-bar-bg: #edf4fd;
$input-label-color: rgba(0, 0, 0, .298039);
$default-text-color: #212121;
$report-page-background: #eee;
$cyan: #00bcd4;

//==== Input chips variable ====//
$chip-y-spacing: 15px;
$chip-x-spacing: 5px;
$chip-button-width: $chip-y-spacing + $chip-x-spacing * 2;
$chip-border-radius: 15px;
$chip-background: #e0e0e0;
$chip-min-height: 36px;
$chip-cross-bg: #a6a6a6;

//==== important ====//
$important: !important;

//==== Text ====//
$text-center: center;
$text-right: right;
$text-left: left;

//==== Font size ====//
$font-size36: 36px;
$font-size34: 34px;
$font-size32: 32px;
$font-size30: 30px;
$font-size28: 28px;
$font-size24: 1.500em; // 24px //
$font-size22: 1.375em; // 22px //
$font-size20: 1.250em; // 20px //
$font-size19: 1.188em; // 19px //
$font-size18: 1.125em; // 18px //
$font-size17: 1.063em; // 17px //
$font-size16: 1em; // 16px //
$font-size15: .938em; // 15px //
$font-size14: .875em;  // 14px //
$font-size13: .813em;  // 13px //
$font-size12: .750em; // 12px //
$base: .625em; // 10px //

//==== Vertical align ==== //
$vertical-top: top;
$vertical-bottom: bottom;
$vertical-middle: middle;
$vertical-sub: sub;
$vertical-sup: sup;

//==== Display ==== //
$display-block: block;
$display-inline-block: inline-block;
$display-flex: flex;
$display-inline-flex: inline-flex;
$display-none: none;

//==== Position ==== //
$position-absolute:absolute;
$position-relative:relative;
$position-fixed:fixed;

//==== Float ==== //
$float-left:left;
$float-right:right;
$float-none:none;

//==== Width in percentage ==== //
$width100:100%;
$width90:90%;
$width80:80%;
$width70:70%;
$width60:60%;
$width50:50%;
$width40:40%;
$width30:30%;
$width20:20%;
$width10:10%;
$width5:5%;
$width0:0%;

//==== Width in pixel ==== //
$width0x:0;
$width1x:10px;
$width2x:20px;
$width3x:30px;
$width4x:40px;
$width5x:50px;
$width6x:60px;
$width7x:70px;
$width8x:80px;
$width9x:90px;
$width10x:100px;

//==== Padding ==== //
$padding0x:0;
$padding1x:10px;
$padding2x:20px;
$padding3x:30px;
$padding4x:40px;
$padding5x:50px;
$padding6x:60px;
$padding7x:70px;
$padding8x:80px;
$padding9x:90px;
$padding10x:100px;

//==== margin==== //
$margin0x:0;
$margin1x:10px;
$margin2x:20px;
$margin3x:30px;
$margin4x:40px;
$margin5x:50px;
$margin6x:60px;
$margin7x:70px;
$margin8x:80px;
$margin9x:90px;
$margin10x:100px;

//==== height ==== //
$height0x:0;
$height1x:10px;
$height2x:20px;
$height3x:30px;
$height4x:40px;
$height5x:50px;
$height6x:60px;
$height7x:70px;
$height8x:80px;
$height9x:90px;
$height10x:100px;

//==== line height ==== //
$line-height0x:0;
$line-height1x:10px;
$line-height2x:20px;
$line-height3x:30px;
$line-height4x:40px;
$line-height5x:50px;
$line-height6x:60px;
$line-height7x:70px;
$line-height8x:80px;
$line-height9x:90px;
$line-height10x:100px;

//==== margin classes ==== //
.m-xxs {margin: 2px 4px;}
.m-xs {margin: 5px;}
.m-sm {margin: 10px;}
.m {margin: 15px;}
.m-md {margin: 20px;}
.m-lg {margin: 30px;}
.m-xl {margin: 50px;}
.m-n {margin: 0 $important;}

.m-l-none {margin-left: 0;}
.m-l-xxs {margin-left: 1px;}
.m-l-xs {margin-left: 5px;}
.m-l-sm {margin-left: 10px;}
.m-l {margin-left: 15px;}
.m-l-md {margin-left: 20px;}
.m-l-mdd {margin-left: 25px;}
.m-l-lg {margin-left: 30px;}
.m-l-xl {margin-left: 40px;}

.m-l-n-xxs {margin-left: -1px;}
.m-l-n-xs {margin-left: -5px;}
.m-l-n-sm {margin-left: -10px;}
.m-l-n {margin-left: -15px;}
.m-l-n-md {margin-left: -20px;}
.m-l-n-lg {margin-left: -30px;}
.m-l-n-xl {margin-left: -40px;}

.m-t-none {margin-top: 0;}
.m-t-xxs {margin-top: 1px;}
.m-t-xs {margin-top: 5px;}
.m-t-sm {margin-top: 10px;}
.m-t {margin-top: 15px;}
.m-t-md {margin-top: 20px;}
.m-t-lg {margin-top: 30px;}
.m-t-xl {margin-top: 40px;}

.m-t-n-xxs {margin-top: -1px;}
.m-t-n-xs {margin-top: -5px $important;}
.m-t-n-sm {margin-top: -10px;}
.m-t-n {margin-top: -15px;}
.m-t-n-md {margin-top: -20px;}
.m-t-n-lg {margin-top: -30px;}
.m-t-n-xl {margin-top: -40px;}

.m-r-none {margin-right: 0;}
.m-r-xxs {margin-right: 1px;}
.m-r-xs {margin-right: 5px;}
.m-r-sm {margin-right: 10px;}
.m-r {margin-right: 15px;}
.m-r-md {margin-right: 20px;}
.m-r-lg {margin-right: 30px;}
.m-r-xl {margin-right: 40px;}

.m-r-n-xxs {margin-right: -1px;}
.m-r-n-xs {margin-right: -5px;}
.m-r-n-sm {margin-right: -10px;}
.m-r-n {margin-right: -15px;}
.m-r-n-md {margin-right: -20px;}
.m-r-n-mdd {margin-right: -60px;}
.m-r-n-lg {margin-right: -30px;}
.m-r-n-xl {margin-right: -40px;}

.m-b-xxs {margin-bottom: 1px;}
.m-b-none {margin-bottom: 0;}
.m-b-xs {margin-bottom: 5px;}
.m-b-sm {margin-bottom: 10px;}
.m-b {margin-bottom: 15px;}
.m-b-md {margin-bottom: 20px;}
.m-b-lg {margin-bottom: 30px;}
.m-b-xl {margin-bottom: 40px;}

.m-b-n-xxs {margin-bottom: -1px;}
.m-b-n-xs {margin-bottom: -5px;}
.m-b-n-sm {margin-bottom: -10px;}
.m-b-n {margin-bottom: -15px;}
.m-b-n-md {margin-bottom: -20px;}
.m-b-n-lg {margin-bottom: -30px;}
.m-b-n-xl {margin-bottom: -40px;}

//==== padding classes ==== //

.p-xxs {padding: 2px 4px;}
.p-xs {padding: 5px;}
.p-sm {padding: 10px;}
.p {padding: 15px;}
.p-md {padding: 20px;}
.p-lg {padding: 30px;}
.p-xl {padding: 50px;}
.p-n {padding: 0 $important;}

.p-l-none {padding-left: 0 !important;}
.p-l-xs {padding-left: 5px;}
.p-l-sm {padding-left: 10px;}
.p-l {padding-left: 15px;}
.p-l-md {padding-left: 20px;}
.p-l-lg {padding-left: 30px;}
.p-l-xl {padding-left: 40px;}

.p-l-n-xxs {padding-left: -1px;}
.p-l-n-xs {padding-left: -5px;}
.p-l-n-sm {padding-left: -10px;}
.p-l-n {padding-left: -15px;}
.p-l-n-md {padding-left: -20px;}
.p-l-n-lg {padding-left: -30px;}
.p-l-n-xl {padding-left: -40px;}

.p-t-none {padding-top: 0;}
.p-t-xxs {padding-top: 1px;}
.p-t-xs {padding-top: 5px;}
.p-t-sm {padding-top: 10px;}
.p-t {padding-top: 15px;}
.p-t-md {padding-top: 20px;}
.p-t-lg {padding-top: 30px;}
.p-t-xl {padding-top: 40px;}

.p-t-n-xxs {padding-top: -1px;}
.p-t-n-xs {padding-top: -5px;}
.p-t-n-sm {padding-top: -10px;}
.p-t-n {padding-top: -15px;}
.p-t-n-md {padding-top: -20px;}
.p-t-n-lg {padding-top: -30px;}
.p-t-n-xl {padding-top: -40px;}

.p-r-none {padding-right: 0;}
.p-r-xxs {padding-right: 1px;}
.p-r-xs {padding-right: 5px;}
.p-r-sm {padding-right: 10px;}
.p-r {padding-right: 15px;}
.p-r-md {padding-right: 20px;}
.p-r-lg {padding-right: 30px;}
.p-r-xl {padding-right: 40px;}

.p-r-n-xxs {padding-right: -1px;}
.p-r-n-xs {padding-right: -5px;}
.p-r-n-sm {padding-right: -10px;}
.p-r-n {padding-right: -15px;}
.p-r-n-md {padding-right: -20px;}
.p-r-n-lg {padding-right: -30px;}
.p-r-n-xl {padding-right: -40px;}

.p-b-none {padding-bottom: 0;}
.p-b-xxs {padding-bottom: 1px;}
.p-b-xs {padding-bottom: 5px;}
.p-b-sm {padding-bottom: 10px;}
.p-b {padding-bottom: 15px;}
.p-b-md {padding-bottom: 20px;}
.p-b-lg {padding-bottom: 30px;}
.p-b-xl {padding-bottom: 40px;}

.p-b-n-xxs {padding-bottom: -1px;}
.p-b-n-xs {padding-bottom: -5px;}
.p-b-n-sm {padding-bottom: -10px;}
.p-b-n {padding-bottom: -15px;}
.p-b-n-md {padding-bottom: -20px;}
.p-b-n-lg {padding-bottom: -30px;}
.p-b-n-xl {padding-bottom: -40px;}
