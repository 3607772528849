.organizations-list-page {
  padding: $padding1x;

  .row {
    margin: 0 -10px $important;
  }

  .page-header-section {
    .title {
      @extend %truncate;
    }
  }

  .org-state-header {
    .title {
      color: $cyan;
      font-size: 22px;
      margin-left: -2px;
      margin-top: 5px;
    }

    .org-state-btn-section {
      margin-right: 175px;
      text-align: right;
    }

    .jump-to-section {
      margin-top: -42px;
    }
  }


  table {
    .prevent-cell-click {
      padding: 0 !important;
    }
    
    th:nth-child(1) {
      width: 50px;
    }

    td:nth-child(1) {
      padding: 0 12px $important;
      width: 50px;

      .table-data span {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 18px $important;
      }
    }
    td:nth-child(2){
      // @extend %truncate;
      // line-height: 48px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .table-data {
        width: 220px;
      }
    }

    th:nth-child(4) {
      width: 100px;
    }

    th:last-child {
      // width: 200px;
    }

    td:nth-child(4) {
      padding: 0 12px $important;
      text-align: $text-right $important;
      width: 100px;
    }

    td:last-child {
      padding: 0 0 $important;
      text-align: $text-right $important;
      // width: 200px;
    }

    .table-data {
      @extend %truncate;
    }
  }

  .two-line-data {
    p {
      @extend %truncate;
      padding: 2px 0;
      width: 230px;
    }
  }

  .table-flat-btn {
    color: $blue800 $important;
    min-width: 190px;

    span {
      @extend %truncate;
    }
  }
}

//==== Media query ====//

@media screen and (min-width:1441px) and (max-width:1600px){

  .organizations-list-page {
    table {

      th {
        padding: 0 10px $important;
      }

      td {
        padding: 0 10px $important;
      }
    }
  }
}

@media screen and (min-width:1366px) and (max-width:1440px){

  .organizations-list-page {
    table {

      th {
        padding: 0 10px $important;
      }

      td {
        padding: 0 10px $important;
      }
    }
  }
}

@media screen and (min-width:1200px) and (max-width:1365px){

  .organizations-list-page {
    table {

      th {
        padding: 0 10px $important;
      }

      td {
        padding: 0 10px $important;
      }
    }
  }
}

@media screen and (min-width:1120px) and (max-width:1290px){
  .prevent-cell-click-wrapper {
    text-align: left $important;

    .table-flat-btn {
      padding: 0 6px !important;

      span {
        font-size: $font-size16 $important;
        padding: 0 $important;
      }
    }
  }
}

@media screen and (min-width:1024px) and (max-width:1199px){
  .prevent-cell-click-wrapper {
    text-align: left $important;

    .table-flat-btn {
      padding: 0 2px $important;

      span {
        font-size: 10px $important;
        padding: 0 $important;
      }
    }
  }
  .organizations-list-page {
    table {

      th {
        padding: 0 10px $important;
      }

      td {
        padding: 0 10px $important;
      }
    }
  }
}



@media screen and (min-width:768px) and (max-width:1023px){
  .organizations-list-page {
    .title-section {
      .title{
        margin-left: $margin2x $important;
      }
    }
  }
  .prevent-cell-click-wrapper {
    text-align: left $important;

    .table-flat-btn {
      padding: 0 4px $important;

      span {
        font-size: $font-size14 $important;
        padding: 0 $important;
      }
    }
  }
}

@media screen and (min-width:768px) and (max-width:890px){
   .organizations-list-page table td:last-child{
    padding: 0 $important
  }
}
