.button-placeholder {
  margin: $margin2x $margin0x;
  text-align: $text-center;
  width: $width100;

  >button {
    margin-left: $margin1x;
  }
}

.button-placeholder-center {
  margin: $margin2x - 5px $margin0x;
  text-align: $text-center;
  width: $width100;
}

.make-mem-btn {
  margin-top: 15px;
  text-align: $text-center;
}

.member-btn-section {
  margin: $margin1x $margin0x;

  .btn-darkcyan {
    margin-right: 10px;
  }
}

.action-icon {
  color: $black $important;
  margin-right: 10px;
}

.member-edit {
  .MuiFormControl-root {
    margin-top: 14px;
  }
}
