.list {
  >div {
    border-bottom: 1px solid $gray2;

    &:first-child {
      border-top: 1px solid $gray2;
    }
  }

  .disabled {
    >div:last-child {
      color: $gray $important;
    }

    .edit-btn {
      display: $display-none $important;
    }

    .disable-avatar {
      background-color: $gray $important;
    }
  }
  li:hover {
    background-color: rgb(236, 234, 234);
  }
}

.list-item {
  cursor: pointer;
  .primary-text,
  .secondary-text {
    @extend %truncate;
    width: $width80;
  }
}
