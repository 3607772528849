.modal-header {
  padding-bottom: 18px $important;
  padding-top: 20px $important;
  h2 {
    font-size: 22px;
  }
}

.modal-scroll {
  max-height: 300px;

  .primary-text,
  .secondary-text {
    @extend %truncate;
    width: $width80;
  }
}

.modal-footer {
  button {
    color: $blue800 $important;

    &.disabled {
      color: $disabled-color $important;
    }
  }
}

.make-owner-message {
  span {
    color: $blue800;
  }
}

p {
  &.modal-body {
    line-height: 24px;
  }
}

.modal-cursor {
  cursor: pointer;
}

.modal-message {
  line-height: 24px;
}

.blue-text {
  color: $blue800;
}


@media only screen and (min-width: 320px) and (max-width: 420px){
  .modal-header.truncate {
    padding: 24px 5px $important;
    text-align: center;
  }

  .modal-header.truncate + div {
    padding: 5px $important;
  }

  .no-archieve-modal  {
    .btn-red {
      margin: 12px 0 $important;
      top: 5px $important;
    }
  }
}