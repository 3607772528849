.Select-menu-outer {
  @extend %dropdown-shadow;
  border: 0 $important;
  border-radius: 0 $important;
}

.multi-select-chip {
  .Select-multi-value-wrapper {
    font-size: $font-size16;
    line-height: 1;
    min-height: $chip-min-height;
  }

  .Select-placeholder {
    display: none;
  }

  .Select-input {
    margin-left: $margin0x $important;
  }

  .Select-control {
    border-bottom: 2px solid $blue800 $important;
  }

  .Select-value {
    background: $chip-background $important;
    border: 0 $important;
    border-radius: $chip-border-radius $important;
    display: $display-inline-block;
    margin: $chip-x-spacing 5px $chip-x-spacing  0 $important;
    position: $position-relative;

    .Select-value-label {
      background: $chip-background;
      border-radius: $chip-border-radius  $chip-border-radius 0;
      color: $black;
      display: $display-inline-block;
      font-weight: 500;
      height: $height3x + 2px;
      line-height: $line-height2x;
      padding: $chip-x-spacing $important;
      padding-left: $chip-y-spacing $important;
      padding-right: $chip-y-spacing / 2 $important;
    }

    .Select-value-icon {
      background: $chip-cross-bg;
      border: 0;
      border-radius: 50% $important;
      bottom: 0;
      color: $white;
      cursor: pointer;
      float: $float-right;
      font-size: $font-size18;
      height: $height2x;
      line-height: $line-height1x + 1px;
      margin: 6px 4px 0 0;
      outline: none;
      padding: $chip-x-spacing;
      position: $position-relative;
      text-align: $text-center;
      top: 0;
      width: $width2x;
    }
  }

  .Select-option {
    font-family: Roboto, sans-serif;
    height: 48px $important;
    line-height: 28px;
  }
}

.Select-value-icon {
  &:focus,
  &:hover {
    background-color: $chip-cross-bg $important;
    color: $white $important;
  }
}
