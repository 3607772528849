.route--active {
  font-weight: bold;
  text-decoration: underline;
}

.app-bar-fixed {
  height: $height6x + 4px;
  position: $position-relative;
  top: 0;
  width: $width100;

  .app-bar {
    box-shadow: $shadow 0 1px 6px, $shadow 0 1px 4px;
  }
}

.app-bar {
  background-color: $white $important;
  position: $position-fixed $important;

  button {
    svg {
      color: $black $important;
      fill: $black $important;
    }
  }
  .logo {
    img {
      height: $height5x + 5px;
      margin: 5px 0 0 10px;
    }
  }
}


@media only screen and (min-width: 1023px) {
  .app-bar {
    button {
      display: $display-none $important;
    }
  }
}

@media screen and (min-width:768px) and (max-width:1023px){
  .logo {
    text-align: center $important;
  }
}