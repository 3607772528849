.no-data-page {
  bottom: auto;
  left: 50% $important;
  margin-top: -30px;
  max-height: calc(100% - 100px) $important;
  position: absolute;
  right: auto;
  text-align: $text-center;
  top: 50% $important;
  // -ms-transform: translate(-50%, -50%) $important;
  // -webkit-transform: translate(-50%, -50%) $important;
  transform: translate(-50%, -50%) $important;

  h3 {
    font-size: Sfont-size19;
    margin: 15px 0 20px;

    .org-name {
      color: $blue800;
    }
  }
}
