//===== Chackbox =====//
.checkbox-section {
  padding: $padding1x $padding0x;
  
  label {
    width: 100%;
    height: 30px $important;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: rgb(0, 188, 212)
  }

  .Mui-disabled {
    color: $dark-gray $important;
    fill: $dark-gray $important;
    opacity: 1 $important;
    transform: scale(1) $important;

    span {
      pointer-events: none $important;
    }
  }
}

.checkbox {
  margin-bottom: $margin1x - 5px;
}
