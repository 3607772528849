.Select-control {
  background-color: $transparent $important;
  border: 0 $important;
  border-bottom: 1px solid $gray2 $important;
  border-radius: 0 $important;
}

.Select-menu-outer {
  background-color: $white $important;
  z-index: 2 $important;
}

.Select-option {
  height: $height5x $important;

  img {
    height: $height2x $important;
    margin-top: 6px $important;
    vertical-align: $vertical-bottom $important;
    width: 40px $important;
  }
}

.is-focused:not(.is-open)>.Select-control {
  box-shadow: none $important;
}

.Select-value-label {
  span {
    img {
      vertical-align: $vertical-middle;
    }
  }
}

.Select--single>.Select-control .Select-value, .Select-placeholder {
  padding-left: $padding0x;
}

.Select-placeholder,
.Select-input,
.Select-value {
  padding-left: $padding0x $important;
}

.Select-option.is-focused {
  background: $blue800 $important;
  color: $white $important;
}
