.unarchive-btn {
  min-width: $width10x + $width1x $important;
}

.no-archieve-modal {
  min-height: 100px;
  position: relative;
  .no-archieve-message {
    text-align: center;
    line-height: 100px;
  }
}


@media only screen and (min-width: 320px) and (max-width: 350px){
  .no-archieve-modal {
    .no-archieve-message {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      line-height: 25px;
    }
  }
}