.getstarted-header {
  display: block;
  margin: -10px 0;
  text-align: center;
  font-size: 22px $important;
}

.getstarted-modal-mar {
  margin: 0 -16px;
  // line-height: 40px;
  padding-top: 20px;

  .modal-sub-header {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 500;
  }

  .form-control {
    width: 100% $important;
    margin-top: 22px;

    .MuiInputLabel-formControl {
      color: rgba(0, 0, 0, 0.3);
    }
    
    &:last-child {
      // margin-bottom: 15px;
    }

  }

  .form-header {
    color: #2c3e50;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    text-transform: uppercase;
  }

  .control-label {
    text-transform: uppercase;
  }
}

.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

.mandatory-icon {
  color: $red500 $important;
  margin-left: 3px;
}


.request-btn-section {
  float: right;
  padding-right: 10px;
}

.request-page-header {
  padding: 5px 0 10px;

  .page-header-section {

    .title {
      color: $cyan;
      font-size: 22px;
      margin-top: 5px;
      margin-left: -13px;
    }
  }
}

.btn-table-section {
  float: right;

  .btn-view {
    color: $blue800 $important;
  }
}

.state-badge {
  color: #263238;
  font-size: 17px;
  padding-left: 5px;
}

.getstarted-footer {
  .btn-flat-darkorange span {
    color: $orange700 $important;
  }

  .btn-flat-darkcyan span {
    @extend %flat-darkcyan;
  }

  .btn-flat-brown span {
    @extend %flat-brown;
  }

  .btn-flat-red span {
    @extend %flat-red;
  }
}

th:nth-child(2) {
  padding: 0 24px $important;
}

td:nth-child(2) {
  padding: 0 24px $important;
}

.org-exist-row {
  background-color: #ffcdd2;

    td:nth-child(1) {
      padding: 0 5px $important;
      width: 40px;
      text-align: center $important;
    }

  .fa-exclamation-triangle {
    font-size: 18px $important;
    color: red $important;
    cursor: pointer;
  }
}

.org-exist-header {
  width: 40px;
  overflow: hidden;
  color: transparent $important;
  padding: 0 $important;
}

.org-exist-column {
  padding: 0 5px $important;
  width: 40px;
}

.snooze-icon {
    margin-left: 5px;
    vertical-align: sub;

  .fa {
    font-size: 20px $important;
    color: #2196f3 $important;
    cursor: pointer;
  }
}

.request-page {
  padding: 10px;

  .MuiTableCell-root {
    padding: 5px;
  }
}

.datepicker-snooze {
  .form-control {
    width: 100% $important;
    margin-top: 15px;
  }
  .snooze-days {
    margin-top: 20px;
  }
}
